<template>
  <div class="box">
    <div class="u-design">
      <div class="edit-box">
        <div class="edit-shop-content" style="min-height: 400px">
            <el-form label-position="right" label-width="120px" size="small">
              <el-form-item label="店铺配色" prop="shop_color">
                <div class="colors">
                  <div class="item" :class="{active: content.shop_color==1}" style="background-color: #42CA4D" title="绿色" @click="content.shop_color=1"></div>
                  <div class="item" :class="{active: content.shop_color==2}" style="background-color: #FF448F" title="粉色" @click="content.shop_color=2"></div>
                  <div class="item" :class="{active: content.shop_color==3}" style="background-color: #FE5C2D" title="橙色" @click="content.shop_color=3"></div>
                  <div class="item" :class="{active: content.shop_color==4}" style="background-color: #e93323" title="红色" @click="content.shop_color=4"></div>
                  <div class="item" :class="{active: content.shop_color==5}" style="background-color: #1db0fc" title="蓝色" @click="content.shop_color=5"></div>
                </div>
              </el-form-item>
              <el-form-item label="商品分类页样式" prop="style">
                <el-radio-group v-model="content.style">
                  <el-radio-button :label="1">样式1</el-radio-button>
                  <el-radio-button :label="2">样式2</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="" prop="">
                <img :src="source_domain + '/images/demo/goods-templage-2.jpg'" width="300" v-if="content.style==1" />
                <img :src="source_domain + '/images/demo/goods-templage-1.jpg'" width="300" v-if="content.style==2" />
              </el-form-item>
            </el-form>
        </div>
      </div>
    </div>
    <div class="saveBox">
      <el-button type="primary" size="small" @click="savePage">保存数据</el-button>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      content: {
        style: 1,
        shop_color: 1
      },
      defaultContent: {
        style: 1,
        shop_color: 1
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.shop.designNavInfo({type:3}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          if(res.data) {
            that.content = res.data
          } else {
            that.content = that.defaultContent
          }
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    savePage() {
      var that = this
      that.showLoading()
      var param = {
        type: 3,
        content: this.content
      }
      this.$api.shop.designNavEdit(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.u-design{min-height:578px; display: flex}
.edit-box{margin-bottom: 70px;width: 600px;}
.edit-head h3{display:inline-block;margin:0 15px 0 0;font-weight:400;font-size:1px;line-height:20px}
.edit-shop-bottomnav {
  background-color: #fff;
}
.edit-shop-bottomnav {
  background-color: #fff;
}
.colors {
  display: flex;
  flex-wrap: wrap;
}
.colors .item {
  display: inline-block;
  background-color: #ccc;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  border:1px solid #fff;
}
.colors .item.active {
  border:1px solid #000;
}
</style>
